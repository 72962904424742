// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getMediasCatalog = createAsyncThunk('appUsers/getMediasCatalog', async params => {
  console.log(params)
  const response = await axios.get('https://apiv3dev.opentlv.com/ia/catalog/images/', {params})
  
  return response.data
  })

export const getMediaCatalog = createAsyncThunk('appUsers/getMediaCatalog', async params => {
    console.log(params)
    const response = await axios.get('https://apiv3dev.opentlv.com/ia/catalog/image', {params})
    
    return response.data
    })

    export const saveAnnotations = createAsyncThunk('appUsers/saveAnnotations', async params => {
      console.log(params)
      const response = await axios.post('https://apiv3dev.opentlv.com/ia/catalog/annotation/image', {params})
      
      return response.data
    })   
    export const deleteAnnotations = createAsyncThunk('appUsers/deleteAnnotations', async params => {
      console.log(params)
      const response = await axios.delete('https://apiv3dev.opentlv.com/ia/catalog/annotation/delete', {params})
      
      return response.data
    })

export const getCatalogs = createAsyncThunk('appUsers/getCatalogs', async id => {
    const response = await axios.get('https://apiv3dev.opentlv.com/ia/catalogs/',  { params: { id} })
    return response.data
  })

  export const getExportCatalogs = createAsyncThunk('appUsers/getExportCatalogs', async id => {
    const response = await axios.get('https://apiv3dev.opentlv.com/ia/exports/',  { params: { id} })
    return response.data
  })
  
export const getAllDataMediasCatalog = createAsyncThunk('appUsers/getAllDataMediasCatalog', async id => {
  const response = await axios.get('https://apiv3dev.opentlv.com/api/v3/catalog/medias/all',  { params: { id} })
  return response.data
})

export const getDataMediasCatlaog = createAsyncThunk('appUsers/getDataMediasCatlaog', async params => {
  const response = await axios.get('https://apiv3dev.opentlv.com/api/v3/catalog/medias/data', {params})
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total
  }
})

export const getUser = createAsyncThunk('appUsers/getUser', async id => {
  const response = await axios.get('https://apiv3dev.opentlv.com/api/v3/users/user', { params: { id} })
  console.log(response.data)
  return response.data
})

export const addUser = createAsyncThunk('appUsers/addUser', async (user, { dispatch, getState }) => {
  await axios.post('/apps/users/add-user', user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return user
})

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})
export const addmedia = createAsyncThunk('appUsers/addMedia', async (obj, { dispatch, getState, rejectWithValue }) => {
  

    console.log(obj)
  const formData = new FormData()
  
 obj.files.forEach((file, index) => {
    formData.append('files[]', file)
  })
  formData.append('catalog_name', obj.catalog_name)
//  formData.append('file', obj.file) 
/*  formData.append('id_catalog', obj.id_catalog) 
  */
  
  try {
    console.log(obj)
    console.log(getState().catalogMedias.params)
    console.log(rejectWithValue)

  const response = await axios.post('https://apiv3dev.opentlv.com/ia/upload/images', formData, {})

  console.log(response)
  if (!response.data.success && !response.data.original) {

    console.log('la')
    console.log(response.data.success)

    return rejectWithValue(response.data)
}
       await dispatch(getDataMediasCatlaog(getState().catalogMedias.params))
      return response.data
//  return response.data

} catch (error) {
console.log('error')
console.log(error)
throw rejectWithValue(error.message)
}
})

export const editmedia = createAsyncThunk('appUsers/editmedia', async (obj, { dispatch, getState, rejectWithValue }) => {
  

  const formData = new FormData()
  
  obj.files.forEach((file, index) => {
    formData.append('files[]', file)
  })
//  formData.append('file', obj.file) 
  formData.append('id_catalog', obj.id_catalog) 
  formData.append('selectedRowId', obj.selectedRowId) 
  
  
  try {
    console.log(obj)
    console.log(getState().catalogMedias.params)
    console.log(rejectWithValue)

  const response = await axios.post('https://apiv3dev.opentlv.com/api/v3/catalog/editmedia', formData, {})

  console.log(response)
  if (!response.data.success && !response.data.original) {

    console.log('la')
    console.log(response.data.success)

    return rejectWithValue(response.data)
}
       await dispatch(getDataMediasCatlaog(getState().catalogMedias.params))
      return response.data
//  return response.data

} catch (error) {
console.log('error')
console.log(error)
throw rejectWithValue(error.message)
}
})

export const transferMediaCatalog = createAsyncThunk('appUsers/transferMediaCatalog', async (params, { dispatch, getState }) => {

  await axios.post('https://apiv3dev.opentlv.com/ia/catalog/move/image', {params})

  return user

})

export const ExportCatalog = createAsyncThunk('appUsers/ExportCatalog', async (params, { dispatch, getState }) => {

  await axios.post('https://apiv3dev.opentlv.com/ia/catalogs/export', {params})

  return user

})
export const deleteMediaIa = createAsyncThunk('appUsers/deleteMediaIa', async (params, { dispatch, getState }) => {

  await axios.delete('https://apiv3dev.opentlv.com/ia/catalog/image/delete', {params})
//  await dispatch(getData(getState().users.params))

  return id
})

export const appClientsSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    catalogs: [],
    exportCatalogs : [],
    image :[],
    images: {
      images: [], // Initialisez avec un tableau vide
      current_page: 1,
      total_images: 0,
      per_page: 200,
      total_pages: 1
    },
    selectedCatalog: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllDataMediasCatalog.fulfilled, (state, action) => {
        state.allData = action.payload.medias
        state.selectedCatalog = action.payload.catalog
        state.groupe = action.payload.groupe
        state.client = action.payload.client
        state.channel = action.payload.channel         
        state.site = action.payload.site         
        
      })
      .addCase(getMediasCatalog.fulfilled, (state, action) => {
        state.images = action.payload
        state.catalogs = action.payload.catalogs.original        
      })
      .addCase(getMediaCatalog.fulfilled, (state, action) => {
        state.image = action.payload
      })

      .addCase(getCatalogs.fulfilled, (state, action) => {
        state.catalogs = action.payload        
      })
      .addCase(getExportCatalogs.fulfilled, (state, action) => {
        state.exportCatalogs = action.payload.exportCatalog     
        state.catalogs = action.payload.catalogs        

      })

      .addCase(getDataMediasCatlaog.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
      .addCase(addmedia.rejected, (state, action) => {
        console.log("reject")
        state.responseValidation = action.payload
      })
      .addCase(addmedia.fulfilled, (state, action) => {
        console.log("fulfilled")
        state.data = action.payload
//        console.log(action.payload)
      })
  }
})

export default appClientsSlice.reducer